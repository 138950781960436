<template>
  <div class="dropdown inline-block relative text-sm">
    <span class="text-grey-2 opacity-50">{{ $t('contracts.sort.title') }}</span>
    <button
      class="text-grey-2 py-2 px-2 rounded inline-flex items-center  no-highlight"
      @click="open"
      @blur="hide"
    >
      <span class="mr-1 font-semibold  no-highlight">{{
        order
          ? $t('projects.orders.' + order)
          : $t('projects.orders.created_at')
      }}</span>
      <svg
        class="transform transition-all fill-current h-4 w-4 opacity-50"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        :class="{'-rotate-180': show}"
      >
        <path
          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
        />
      </svg>
    </button>
    <ul
      class="
        dropdown-menu
        absolute
        text-gray-700
        z-50
        w-max
        rounded-2xl
        bg-white
        right-3
        shadow-order
        transform
        transition-all
        origin-top
      "
      style="min-width: 10rem"
      :class="show ? 'scale-y-100' : 'scale-y-0'"
    >
      <GeneralInput
        v-model:data="optionSelected"
        type="radio"
        name="owner"
        :options="optionsObject"
        noMargin
        :radioGreenBg="false"
        noLines
        @blured="hide"
      />

    </ul>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from "vue-i18n";
import GeneralInput from '@/components/general/Input.vue'

export default {
  props: {
    mkt: {
      type: Boolean,
      default: false
    }
  },
  components: {GeneralInput},
  setup(props) {
    const router = useRouter()
    const show = ref(false)
    const store = useStore()
    const optionSelected = ref('created_at')
    const i18n = useI18n({ useScope: 'global' })

    const options = ref([
      'created_at',
      '-created_at',
      'interest_rate',
      '-interest_rate'
    ])

    const order = computed(() => {
      return store.state.projects.order
    })

    function hide(evt) {
      if(!evt.relatedTarget || evt.relatedTarget.name !== 'radio-input')
          show.value = false
    }

    const open = () => {
      options.value = props.mkt
        ? ['created_at', '-created_at']
        : ['created_at', '-created_at', 'interest_rate', '-interest_rate']
      show.value = !show.value
    }

    const optionsObject = computed(()=>{
      let obj = {}
      options.value.forEach((el)=>{
        obj[el] = i18n.t('projects.orders.' + el)
      })
      return obj
    })

    watch(
      () => optionSelected.value,
      async () => {
        router.push({
          query: { order: optionSelected.value }
        })
        store.dispatch('setOrder', optionSelected.value)
      }
    )

    function setOrder(order) {
      router.push({
        query: { order: order }
      })
      store.dispatch('setOrder', order)
    }
    return { show, options, setOrder, order, hide, open, optionSelected, optionsObject }
  }
}
</script>
